import { JsonProperty } from 'utils/maputils';
import { BaseStruct } from 'entities/baseStruct'
import { Trabajador } from './trabajador';
import { EstadoDoc } from './documento';
import { GetAPI } from 'utils/api';

export class TrabajadorCdt extends BaseStruct {
  empresa_cdt_id: number = undefined;
  presente: number = undefined;
  favorito: number = undefined;
  fecha_entrada: string = "";
  fecha_salida: string = "";
  estado_doc: EstadoDoc = undefined;
  // @JsonProperty({ name: "trabajador", clazz: Trabajador })
  trabajador: Trabajador = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get nombreCompleto(): string {
    if (!this.trabajador) {
      return "";
    }

    let nombre = this.trabajador.nombre;
    if (this.trabajador.apellido1) {
      nombre += " " + this.trabajador.apellido1;
    }

    if (this.trabajador.apellido2) {
      nombre += " " + this.trabajador.apellido2;
    }

    return nombre;
  }

  get nombreCompletoCif(): string {
    if (!this.trabajador) {
      return "";
    }

    let nombre = this.trabajador.nombre;
    if (this.trabajador.apellido1) {
      nombre += " " + this.trabajador.apellido1;
    }

    if (this.trabajador.apellido2) {
      nombre += " " + this.trabajador.apellido2;
    }

    if (this.trabajador.nif) {
      nombre += " (" + this.trabajador.nif + ")";
    }
    return nombre;
  }

  get sexoStr(): string {
    let api = GetAPI();
    let str = "";
    if (this.trabajador.sexo == 0) {
      str = api.tr('hombre');
    } else {
      str = api.tr('mujer');
    }

    return str;
  }
}
