import { BaseStruct } from 'entities/baseStruct'
import { Direccion } from 'entities/direccion'
import { EstadoDoc, Documento } from './documento';
import { Empresa } from './empresa';
import { JsonProperty } from 'utils/maputils';
import { GetAPI } from 'utils/api';

export class Trabajador extends BaseStruct {
  nombre: string = "";
  apellido1: string = "";
  apellido2: string = "";
  ipf: number = undefined;
  nif: string = "";
  sexo: number = undefined;
  nass: string = "";
  fecha_nac: string = "";
  direccion: Direccion = undefined;
  activo: number = undefined;
  fase_alta: string = "";
  fase_baja: string = "";
  fase_ccc: string = "";
  categoria: string = "";
  puesto: string = "";
  bloqueado: number = undefined;

  empresa: number = undefined;
  estado_doc: EstadoDoc = undefined;

  //cacheados

  @JsonProperty('-')
  empresaCached: Empresa = undefined;
  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get nombreCompleto(): string {
    let nombre = this.nombre;
    if (this.apellido1) {
      nombre += " " + this.apellido1;
    }

    if (this.apellido2) {
      nombre += " " + this.apellido2;
    }

    return nombre;
  }

  get nombreCompletoCif(): string {
    let nombre = this.nombreCompleto;
    if (this.nif) {
      nombre += " (" + this.nif + ")";
    }
    return nombre;

    // return this.nombre + " " + this.apellido1 + " " + this.apellido2 + " (" + this.nif + ")";
  }

  get sexoStr(): string {
    let api = GetAPI();
    let str = "";
    if (this.sexo == 0) {
      str = api.tr('hombre');
    } else {
      str = api.tr('mujer');
    }

    return str;
  }
}

export class TrabajadorValidado {
  @JsonProperty({ name: 'empresa', clazz: Empresa })
  empresa: Empresa = undefined;
  @JsonProperty({ name: 'trabajador', clazz: Trabajador })
  trabajador: Trabajador = undefined;
  @JsonProperty({ name: 'documentos', clazz: Documento })
  documentos: Documento[] = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
  }
}

