import { BaseStruct } from "./baseStruct";

export class Direccion extends BaseStruct {
  direccion: string = "";
  cp: string = "";
  local: string = "";
  provincia: string = "";
  pais: string = "";
  tel: string = "";
  movil: string = "";
  email: string = "";
  web: string = "";
  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get resumen(): string {
    return this.direccion + " - " + this.cp;
  }

} 
