import { GetAPI } from 'utils/api';
import { JsonProperty } from 'utils/maputils'
import { BaseStruct } from 'entities/baseStruct'
import { Documento, EstadoDoc } from './documento';
import moment from 'moment';
import { EmpresaCdT } from './centro';
import { AuthData } from 'authService';

export enum TipoActividadPreventiva {
  TODOS = "",
  AnexoPSS = "P",
  AptitudMedica = "R",
  ReunionSeguridad = "C",
  EntregaMaterial = "S",
  Formacion = "B",
  Informes = "H",
  InspeccionTrabjo = "D",
  LibroIncidencias = "N",
  PermisosTrabajo = "J",
  PlanEmergenciaDesignacion = "F",
  PlanEmergenciaMediosProteccion = "G",
  Auditoria = "I",
  VisitaCentro = "A",
  Varios = "E",
  GestionDocumental = "K",
  PlanEvacuacionPlanificacion = "L"
}

export enum EstadoActividadPreventiva {
  Abierta = "2",
  Ejecutandose = "5",
  Finalizada = "6",
  Anulada = "4",
  NoAplica = "1",
  CerradoyPF = "8",
  CerradoF_PC = "9",
  CERRADO = "3",
  Incidentado = "7",
  PdtValidacion = "0",
}


export enum TipoAsistencia {
  Programado = "3",
  Confirmado = "4",
  APTO = "1",
  NoAsistio = "0",
  Asistio = "2",
  Anulado48h = "5",
  Anulado24h = "6",
  Anulado = "7",
  ReProgram = "9",
  Convalidado = "8",
}

export function getTipoAsistencia(): any[] {
  let list = [];
  list.push({ "key": "3", "description": "Programado" });
  list.push({ "key": "4", "description": "Confirmado" });
  list.push({ "key": "1", "description": "APTO" });
  list.push({ "key": "0", "description": "No Asistio" });
  list.push({ "key": "2", "description": "Asistio" });
  list.push({ "key": "5", "description": "Anulado 48h" });
  list.push({ "key": "6", "description": "Anulado 24h" });
  list.push({ "key": "7", "description": "Anulado" });
  list.push({ "key": "9", "description": "Re-Program." });
  list.push({ "key": "8", "description": "Convalidado" });
  return list;
}

// export function getCategoriasPEE(): any[] {
//   let list = [];
//   list.push({ "key": "1", "description": "Responsable de emergencia" });
//   list.push({ "key": "2", "description": "Responsable de comunicación" });
//   list.push({ "key": "3", "description": "Responsable de intervención" });
//   list.push({ "key": "4", "description": "Miembro del equipo de alarma y evacuación" });
//   list.push({ "key": "5", "description": "Miembro del equipo de primera intervención" });
//   return list;
// }

// export function getCargosPEE(): any[] {
//   let list = [];
//   list.push({ "key": "1", "description": "Principal" });
//   list.push({ "key": "2", "description": "Suplente" });
//   return list;
// }

// export function getTurnosPEE(): any[] {
//   let list = [];
//   list.push({ "key": "A", "description": "Mañana" });
//   list.push({ "key": "B", "description": "Tarde" });
//   list.push({ "key": "C", "description": "Noche" });
//   list.push({ "key": "D", "description": "Mañana y tarde" });
//   return list;
// }

export function getTipoMediosPE(): any[] {
  let list = [];
  list.push({ "key": "1", "description": "Polvo ABC" });
  list.push({ "key": "2", "description": "CO2" });
  list.push({ "key": "3", "description": "BIE" });
  list.push({ "key": "4", "description": "Detectores" });
  list.push({ "key": "5", "description": "Alumbrado" });
  list.push({ "key": "6", "description": "Pulsadores" });
  return list;
}

export function getTipoReunion(): any[] {
  let list = [];
  list.push({ "key": "1", "description": "Convocatoria" });
  list.push({ "key": "2", "description": "Reunión" });
  return list;
}

export function getGestionActPreventiva(): any[] {
  let list = [];
  list.push({ "key": "A", "description": ". (sin especificar)" });
  list.push({ "key": "B", "description": "Diseño e implantación del Plan de Prevención según Ley 54/03" });
  list.push({ "key": "C", "description": "Evaluación (Inicial) de Riesgos" });
  list.push({ "key": "D", "description": "Revisión o actualización de la Evaluación (Inicial) de Riesgos" });
  list.push({ "key": "E", "description": "Planificación de la Actividad Preventiva" });
  list.push({ "key": "F", "description": "Memoria de las Actividades Preventivas" });
  list.push({ "key": "G", "description": "Memoria de las Actividades Sanitarias" });
  list.push({ "key": "H", "description": "Memoria de las Actividades Preventivas y Sanitarias" });
  return list;
}

export function getGestionActSanitaria(): any[] {
  let list = [];
  list.push({ "key": "A", "description": ". (sin especificar)" });
  list.push({ "key": "C", "description": "Planificación de Reconocimientos Médicos Laborales" });
  list.push({ "key": "B", "description": "Reconocimientos Médicos Laborales" });

  return list;
}

export function getGestionActGestion(): any[] {
  let list = [];
  list.push({ "key": "A", "description": ". (sin especificar)" });
  list.push({ "key": "D", "description": "00. Enviar Presupuesto de Producto/s Servicios de NUEVA Contratación" });
  list.push({ "key": "E", "description": "01. Enviar Presupuesto de RENOVACIÓN de Producto/s Servicios ya Contratados" });
  list.push({ "key": "F", "description": "02. Consultar Estado del Presupuesto" });
  list.push({ "key": "G", "description": "03. Solicitar Hacer Factura/s para enviar al Cliente" });
  list.push({ "key": "H", "description": "04. Reclamar Factura/s pendientes de Pago" });
  list.push({ "key": "Ñ", "description": "05. Solicitar Hacer Contrato" });
  list.push({ "key": "7", "description": "06. Solicitar Hacer Factura de AMPLIACIÓN de Servicios" });
  list.push({ "key": "8", "description": "07. Consultar al Dpto. de Contabilidad el Estado de un Cliente/Proveedor" });
  list.push({ "key": "9", "description": "08. Solicitar el DESBLOQUEO (Acceso, Documental) de una EMPRESA" });
  list.push({ "key": "J", "description": "11. Estudio de Documentación entregada por el Cliente y Propuesta Soluciones" });
  list.push({ "key": "K", "description": "20. Creación/Homologación Documental de EMPRESA/S NUEVAS" });
  list.push({ "key": "L", "description": "21. Gestionar Documentación de EMPRESA/s existentes" });
  list.push({ "key": "M", "description": "22. Procesar y Cargar Informe de Vida Laboral" });
  list.push({ "key": "5", "description": "23. Generar los 2 Certificados como SPA para que la EMP pueda solicitar el REA" });
  list.push({ "key": "I", "description": "24. Generar Documentación en PRL (PLAN, EVA, PLANIF)" });
  list.push({ "key": "4", "description": "25. Generar Certificado de Corriente Pago de SPA" });
  list.push({ "key": "N", "description": "30. Creación/Homologación Documental de Trabajador/es NUEVOS" });
  list.push({ "key": "O", "description": "31. Gestionar Documentación de Trabajador/es existentes" });
  list.push({ "key": "6", "description": "32. Validar DIPLOMAS según Convenio Construcción de Trabajadores" });
  list.push({ "key": "P", "description": "40. Gestionar Documentación de Equipos de Trabajo NUEVOS" });
  list.push({ "key": "Q", "description": "41. Gestionar Documentación de Equipos de Trabajo existentes" });
  list.push({ "key": "R", "description": "50. <-- ATENDER una llamada telefónica" });
  list.push({ "key": "S", "description": "51. --> LLAMAR por teléfono" });
  list.push({ "key": "B", "description": "60. --> ENVIAR un E-mail" });
  list.push({ "key": "C", "description": "61. <-- GESTIONAR un E-mail recibido" });
  list.push({ "key": "T", "description": "70. --> ENVIAR un WH/SMS/Telegram" });
  list.push({ "key": "U", "description": "71. <-- GESTIONAR un WH/SMS/Telegram recibido" });
  list.push({ "key": "V", "description": "80. IMPRIMIR y Copia de Documentos en Papel" });
  list.push({ "key": "W", "description": "81. ESCANEADO de Documentos" });
  list.push({ "key": "X", "description": "82. ORGANIZAR Carpetas/Archivo Documental" });
  list.push({ "key": "Y", "description": "90. PROGRAMAR una Reunión" });
  list.push({ "key": "Z", "description": "91. ASISTIR a una Reunión Presencial" });
  list.push({ "key": "0", "description": "100. ATENDER a un FUTURO CLIENTE" });
  list.push({ "key": "1", "description": "101. ATENDER a un CLIENTE" });
  list.push({ "key": "2", "description": "102. ATENDER a un PROVEEDOR" });
  list.push({ "key": "3", "description": "103. ATENDER a un COLABORADOR/Compañero/a" });
  return list;
}


export class SubtipoActividadPreventiva {
  id: string = "";
  nombre: string = "";
  acts: string[] = undefined;

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

function estadoNombre(id: string) {
  let api = GetAPI();

  switch (id) {
    case EstadoActividadPreventiva.Abierta:
      return api.tr("abierta");
    case EstadoActividadPreventiva.Ejecutandose:
      return api.tr("ejecutandose");
    case EstadoActividadPreventiva.Finalizada:
      return api.tr("finalizada");
    case EstadoActividadPreventiva.Anulada:
      return api.tr("anulada");
    case EstadoActividadPreventiva.NoAplica:
      return api.tr("no_aplica");
    case EstadoActividadPreventiva.CerradoyPF:
      return api.tr("cerrado_y_pf");
    case EstadoActividadPreventiva.CerradoF_PC:
      return api.tr("cerrado_f_pc");
    case EstadoActividadPreventiva.CERRADO:
      return api.tr("cerrado");
    case EstadoActividadPreventiva.Incidentado:
      return api.tr("incidentado");
    case EstadoActividadPreventiva.PdtValidacion:
      return api.tr("pdt_validacion");
  }
  return "";
}

export class ActividadPreventiva extends BaseStruct {
  nombre: string = "";
  tecnico_id: number = undefined;
  tecnico: string = "";
  empresa_cdt_id: number;
  empresa_cdt: string = "";
  cdt_id: number = undefined;
  cdt: string = "";
  fecha_inicio: string = "";
  hora_inicio: string = "";
  fecha_fin: string = "";
  hora_fin: string = "";
  tipo: string = "";
  tipo_id: string = "";
  subtipo_id: string = "";
  subtipo: string = "";
  estado: string = "";
  estado_id: string = "";
  obs_publicas: string = "";
  obs_internas: string = "";
  lugar: string = "";
  atributos: string = "";
  cod3: string = "";
  min_trabs: number = undefined;
  min_trabs_sim: number = undefined;
  cod_act: string = "";
  // CAMPOS FORMACION
  form_curso: string = "";
  form_director: number = undefined;
  form_formador: string = "";
  form_examen_fecha: string = "";
  form_examen_hora_inicio: string = "";
  form_examen_hora_fin: string = "";
  // CAMPOS PLAN DE EMERGENCIA Y EVACUACIÓN
  pe_actividad: string = "";
  pe_actividad_id: number = undefined;
  pe_actividad_nombre: string = "";
  pe_lugar: string = "";
  pe_lugar_id: number = undefined;
  pe_lugar_nombre: string = "";
  pe_megafonia: string = "";
  pe_megafonia_oculto: number = undefined;
  pe_megafonia_id: number = undefined;
  pe_megafonia_nombre: string = "";
  pe_jefe: number = undefined;
  pe_jefe_texto: string = "";
  pe_jefe_sup1: number = 0;
  pe_jefe_sup1_texto: string = "";
  pe_jefe_sup2: number = 0;
  pe_jefe_sup2_texto: string = "";
  pe_jefe_sup3: number = 0;
  pe_jefe_sup3_texto: string = "";
  pe_fecha: string = "";
  pe_zona: string = "";
  pe_tipo: string = "";// TIPOS
  pe_emp_sub: number = undefined;
  pe_emp_man: number = undefined;
  pe_mp_fecha_compra: string = "";
  pe_mp_fecha_revision: string = "";
  pe_mp_fecha_prox_revision: string = "";
  pe_sim_fechas: number = undefined;
  pe_sim_fecha_inicio: string = "";
  pe_sim_hora_inicio: string = "";
  pe_sim_fecha_fin: string = "";
  pe_sim_hora_fin: string = "";

  // CAMPOS REUNION
  reunion_fase_id: string = "";
  reunion_fase: string = "";
  reunion_tipo_id: number = undefined;
  reunion_tipo: string = "";
  reunion_otro_personal: string = "";
  reunion_orden_dia: string = "";
  // INFORME
  inf_tipo_id: number = undefined;
  inf_tipo_nombre: string = "";
  inf_indice: string = "";
  inf_fecha: string = "";
  inf_version: number = undefined;
  inf_insp_id: number = undefined;
  inf_insp_nombre: string = "";
  // INSPECCIÓN DE TRABAJO
  insp_estado_id: number = undefined;
  insp_estado_nombre: string = "";
  insp_posible_sancion: number = undefined;
  insp_sancion: number = undefined;
  insp_ref_expediente: string = "";
  insp_observaciones: string = "";
  // GESTION DOCUMENTAL
  ges_act_prev_id: string = "";
  ges_act_prev_nombre: string = "";
  ges_act_sanitaria_id: string = "";
  ges_act_sanitaria_nombre: string = "";
  ges_act_gestion_id: string = "";
  ges_act_gestion_nombre: string = "";
  ges_tipo_suceso_id: string = "";
  ges_tipo_suceso_nombre: string = "";
  ges_subtipo_act_id: string = "";
  ges_subtipo_act_nombre: string = "";
  // LIBRO INCIDENCIAS
  lib_tipo_id: number = undefined;
  lib_tipo_nombre: string = "";
  lib_tomo: number = undefined;
  lib_pag_desde: number = undefined;
  lib_pag_hasta: number = undefined;
  lib_registra_id: number = undefined;
  lib_registra_nombre: string = "";
  lib_insp_id: number = undefined;
  lib_insp_nombre: string = "";


  @JsonProperty('-')
  empCT: EmpresaCdT;

  @JsonProperty('-')
  trabajadores: TrabajadorActividadPreventiva[] = [];

  @JsonProperty('-')
  documentos: Documento[] = [];

  @JsonProperty('-')
  situacionesRiesgo: MedidaCorrectora[] = [];

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get trabajadoresActivos(): TrabajadorActividadPreventiva[] {

    let trabs: TrabajadorActividadPreventiva[] = [];

    // let fFin = moment(); // Hoy
    // if (this.fecha_fin) {
    //   fFin = moment(this.fecha_fin + " " + this.hora_fin, "DD-MM-YYYY HH:mm:ss");
    // }

    for (let t of this.trabajadores) {
      // let fDesasignacion = moment(t.fecha_desasignacion, "DD-MM-YYYY");
      // if (fDesasignacion.isValid() && fDesasignacion < fFin) {
      //   continue;
      // }

      if (t.fecha_desasignacion != "") {
        continue;
      }
      trabs.push(t);
    }

    return trabs;
  }

  get tipoNombre(): string {

    let api = GetAPI();

    switch (this.tipo_id) {
      case TipoActividadPreventiva.AnexoPSS:
        return api.tr("anexo_pss")
      case TipoActividadPreventiva.GestionDocumental:
        return api.tr("gest_doc")
      case TipoActividadPreventiva.Informes:
        return api.tr("informe")
      case TipoActividadPreventiva.InspeccionTrabjo:
        return api.tr("inspeccion")
      case TipoActividadPreventiva.LibroIncidencias:
        return api.tr("lib_incid")
      case TipoActividadPreventiva.PlanEmergenciaDesignacion:
        return api.tr("pee_des_per")
      case TipoActividadPreventiva.ReunionSeguridad:
        return api.tr("reunion")
      case TipoActividadPreventiva.VisitaCentro:
        return api.tr("visita_cdt")
      case TipoActividadPreventiva.Formacion:
        return api.tr("formacion")
      case TipoActividadPreventiva.AptitudMedica:
        return api.tr("aptitud_medica")
      case TipoActividadPreventiva.EntregaMaterial:
        return api.tr("entrega_material")
      case TipoActividadPreventiva.PermisosTrabajo:
        return api.tr("permiso_trabajo");
      case TipoActividadPreventiva.PlanEmergenciaMediosProteccion:
        return api.tr("plan_emergencia_medios_proteccion");
      case TipoActividadPreventiva.Auditoria:
        return api.tr("auditoria");
      case TipoActividadPreventiva.PlanEvacuacionPlanificacion:
        return api.tr("plan_evaluacion_planificacion");
      case TipoActividadPreventiva.Varios:
        return api.tr("varios");
    }
  }

  get estadoNombre(): string {
    return estadoNombre(this.estado_id);
  }


  get timestamp(): Date {

    let dateStr = this.fecha_inicio;
    if (dateStr != "" && this.hora_inicio != "") {
      dateStr = this.fecha_inicio + " " + this.hora_inicio;
    }

    let date = moment(dateStr, 'DD-MM-YYYY HH:mm:ss').toDate();
    // console.log("timestamp", "STR", dateStr, "DATE", date);
    return date;
  }


  get duracionSimulacro(): string {
    let d1 = moment(this.pe_sim_fecha_inicio + " " + this.pe_sim_hora_inicio, 'DD-MM-YYYY HH:mm:ss');
    let d2 = moment(this.pe_sim_fecha_fin + " " + this.pe_sim_hora_fin, 'DD-MM-YYYY HH:mm:ss');

    // let secs = d2.diff(d1, 'seconds');
    // return moment.utc(moment.duration(secs, "seconds").asMilliseconds()).format("hh:mm:ss");
    var ms = d2.diff(d1);
    var d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
  }

  get tieneTab(): boolean {
    return this.tipo_id != 'P'
  }

  get guardaCambios(): boolean {
    return !(this.tipo_id == 'A' || this.tipo_id == 'G' || this.tipo_id == 'P');
  }

  get peSimFechasBool(): boolean {
    return this.pe_sim_fechas == 1;
  }

  set peSimFechasBool(v: boolean) {
    this.pe_sim_fechas = v ? 1 : 0;
  }

  puedeDuplicar(authData: AuthData): boolean {

    if (!authData) {
      return false;
    }

    switch (this.tipo_id) {
      case TipoActividadPreventiva.PlanEmergenciaDesignacion:
        return authData.prl_duplicar_pee_des_per;
      case TipoActividadPreventiva.AnexoPSS:
      case TipoActividadPreventiva.GestionDocumental:
      case TipoActividadPreventiva.Informes:
      case TipoActividadPreventiva.InspeccionTrabjo:
      case TipoActividadPreventiva.LibroIncidencias:
      case TipoActividadPreventiva.ReunionSeguridad:
      case TipoActividadPreventiva.VisitaCentro:
      case TipoActividadPreventiva.Formacion:
      case TipoActividadPreventiva.AptitudMedica:
      case TipoActividadPreventiva.EntregaMaterial:
      case TipoActividadPreventiva.PermisosTrabajo:
      case TipoActividadPreventiva.PlanEmergenciaMediosProteccion:
      case TipoActividadPreventiva.Auditoria:
      case TipoActividadPreventiva.PlanEvacuacionPlanificacion:
      case TipoActividadPreventiva.Varios:
    }
    return false;
  }

  puedeDuplicarChecks(authData: AuthData): boolean {

    if (!authData) {
      return false;
    }

    switch (this.tipo_id) {
      case TipoActividadPreventiva.PlanEmergenciaDesignacion:
        console.log("puedeDuplicarChecks", authData.prl_duplicar_checks_pee_des_per);
        return authData.prl_duplicar_checks_pee_des_per;
      case TipoActividadPreventiva.AnexoPSS:
      case TipoActividadPreventiva.GestionDocumental:
      case TipoActividadPreventiva.Informes:
      case TipoActividadPreventiva.InspeccionTrabjo:
      case TipoActividadPreventiva.LibroIncidencias:
      case TipoActividadPreventiva.ReunionSeguridad:
      case TipoActividadPreventiva.VisitaCentro:
      case TipoActividadPreventiva.Formacion:
      case TipoActividadPreventiva.AptitudMedica:
      case TipoActividadPreventiva.EntregaMaterial:
      case TipoActividadPreventiva.PermisosTrabajo:
      case TipoActividadPreventiva.PlanEmergenciaMediosProteccion:
      case TipoActividadPreventiva.Auditoria:
      case TipoActividadPreventiva.PlanEvacuacionPlanificacion:
      case TipoActividadPreventiva.Varios:
    }
    return false;
  }


}


export class ActividadPreventivaDup extends BaseStruct {
  dup_sit_rie: number = undefined;
  dup_trab: number = undefined;


  get duplicarSitRieBool(): boolean {
    return this.dup_sit_rie == 1;
  }

  set duplicarSitRieBool(v: boolean) {
    this.dup_sit_rie = v ? 1 : 0;
  }

  get duplicarTrabBool(): boolean {
    return this.dup_trab == 1;
  }

  set duplicarTrabBool(v: boolean) {
    this.dup_trab = v ? 1 : 0;
  }


}


export class MedidaPreventiva extends BaseStruct {
  med_prev: string = "";
  med_prev_nombre: string = "";
  urgente: number = undefined;
  estado: string;
  hay_evi: number = undefined;
  mp_especifica: string = "";
  mp_especifica_valor: string = "";
  mp_especifica_por: number = undefined;
  // Plazos
  fecha_inicio: string = "";
  resp_inicio: number = undefined;
  resp_inicio_nombre: string = "";

  fecha_prevista: string = "";

  fecha_implementacion: string = "";
  resp_implementacion: number = undefined;
  resp_implementacion_nombre: string = "";

  fecha_aprobacion: string = "";
  resp_aprobacion: number = undefined;
  resp_aprobacion_nombre: string = "";
  desc_aprobacion: string = "";


  requiere_ot: number = undefined;
  ot: string = "";

  efi: string = "";
  efi_porcentaje: number = undefined;


  //   responsable: number = undefined;
  //   responsable_nombre: string = "";
  //   descripcion_verificacion: string = "";


  @JsonProperty('-')
  changed: boolean = false;

  @JsonProperty('-')
  medCorrectora: MedidaCorrectora = undefined;

  get mp_especifica_valor_desc(): string {
    let api = GetAPI()
    if (this.mp_especifica_valor == "") {
      return api.tr("sin_especificar");
    } else if (this.mp_especifica_valor == "0") {
      return api.tr("incorrecto");
    } else if (this.mp_especifica_valor == "1") {
      return api.tr("correcto");
    } else if (this.mp_especifica_valor == "2") {
      return api.tr("no_aplica");
    } else if (this.mp_especifica_valor == "3") {
      return api.tr("en_ejecucion");
    }
    return "??"
  }

  get mp_especifica_valor_col(): string {
    if (this.mp_especifica_valor == "") {
      return "col-1";
    } else if (this.mp_especifica_valor == "1") {
      return "col-3";
    } else if (this.mp_especifica_valor == "0") {
      return "col-5";
    } else if (this.mp_especifica_valor == "2") {
      return "col-7";
    } else if (this.mp_especifica_valor == "3") {
      return "col-9";
    }
    return ""
  }

  get requireOTBool(): boolean {
    return this.requiere_ot == 1;
  }

  set requireOTBool(v: boolean) {
    this.requiere_ot = v ? 1 : 0;
  }

  get requiereFechaImp(): boolean {
    // return this.inc_especifica_valor == '1' && (this.fecha_inicio == "" || this.fecha_implementacion == "");
    return this.mp_especifica_valor == '1' && this.fecha_inicio != "" /*&& this.fecha_implementacion == ""*/;
  }

  requiereFechaImpUno(): boolean {
    return this.requiereFechaImp;
  }

}

export class MedidaCorrectora extends BaseStruct {
  actividad_id: number = undefined;
  actividad_nombre: string = "";
  ap_fecha: string = "";
  ap_estado_id: string = "";
  ap_estado: string = "";
  ap_cod3: string = "";

  acc_trab_id: number = undefined;
  acc_trab_nombre: string = "";
  acc_trab_fecha: string = "";
  acc_trab_baja: number = undefined;
  acc_trab_acc_nombre: string = "";
  acc_trab_acc_tipo_id: string = "";

  urgente: number = undefined;
  estado: string;
  grado: string;
  codigo: string = "";
  ig_id: string = "";
  ig_nombre: string = "";
  ig_grupo: string = "";
  ig_desc: string = "";
  ig_desc_actuacion: string = "";
  ig_desc_items: string = "";
  ig_desc_bien: string = "";
  ig_desc_mal: string = "";
  ig_desc_sin_esp: string = "";
  ig_desc_no_aplica: string = "";
  ig_desc_ejecucion: string = "";

  inc_especifica: string = "";
  inc_especifica_valor: string = "";
  inc_especifica_por: number = undefined;
  cerrada: number = undefined;

  @JsonProperty({ clazz: MedidaPreventiva })
  med_prev: MedidaPreventiva[] = [];

  @JsonProperty('-')
  actPrev: ActividadPreventiva;

  @JsonProperty('-')
  accTrab: TrabajadorAccidente;

  @JsonProperty('-')
  changed: boolean = false;

  update() {
    for (let mp of this.med_prev) {
      mp.medCorrectora = this;
    }
  }

  get inc_especifica_valor_desc(): string {
    let api = GetAPI()
    if (this.inc_especifica_valor == "") {
      return api.tr("sin_especificar");
    } else if (this.inc_especifica_valor == "0") {
      return api.tr("incorrecto");
    } else if (this.inc_especifica_valor == "1") {
      return api.tr("correcto");
    } else if (this.inc_especifica_valor == "2") {
      return api.tr("no_aplica");
    } else if (this.inc_especifica_valor == "3") {
      return api.tr("en_ejecucion");
    }
    return "??"
  }

  get ap_estado_nombre(): string {
    return estadoNombre(this.ap_estado_id);
  }

  get inc_especifica_valor_col(): string {
    if (this.inc_especifica_valor == "") {
      return "col-1";
    } else if (this.inc_especifica_valor == "1") {
      return "col-3";
    } else if (this.inc_especifica_valor == "0") {
      return "col-5";
    } else if (this.inc_especifica_valor == "2") {
      return "col-7";
    } else if (this.inc_especifica_valor == "3") {
      return "col-9";
    }
    return ""
  }

  get accTipoNombre(): string {

    let api = GetAPI();

    switch (this.acc_trab_acc_tipo_id) {
      case "0":
        return api.tr("accidente_recaida")
      case "1":
        return api.tr("incidente")
      case "2":
        return api.tr("patologia")
      case "3":
        return api.tr("enfermedad_profesional")
      case "4":
        return api.tr("enfermedad_comun")
    }
  }

  get accBajaNombre(): string {

    let api = GetAPI();

    if (this.acc_trab_baja == 1) {
      return api.tr("con_baja");
    } else {
      return api.tr("sin_baja");
    }
  }

  // get requireOTBool(): boolean {
  //   return this.requiere_ot == 1;
  // }

  // set requireOTBool(v: boolean) {
  //   this.requiere_ot = v ? 1 : 0;
  // }

  // get requiereFechaImp(): boolean {
  //   // return this.inc_especifica_valor == '1' && (this.fecha_inicio == "" || this.fecha_implementacion == "");
  //   return this.inc_especifica_valor == '1' && this.fecha_inicio != "" /*&& this.fecha_implementacion == ""*/;
  // }

  // requiereFechaImpUno(): boolean {
  //   return this.requiereFechaImp;
  // }

  get estadoEditable(): boolean {
    return !this.med_prev || this.med_prev.length == 0;
  }

}

export class TrabajadorActividadPreventiva extends BaseStruct {
  trab_id: number = undefined;
  trab_nombre: string = "";
  trab_apellidos: string = "";
  trab_empresa: string = "";
  trab_nif: string = "";
  actividad_id: number = undefined;
  fecha: string = "";
  hora: string = "";
  estado_id: string = "";
  estado: string = "";
  log: string = "";
  pee_categoria: string = "";
  pee_categoria_id: number = undefined;
  pee_cargo: string = "";
  pee_cargo_id: number = undefined;
  pee_turno: string = "";
  pee_turno_id: number = undefined;
  fecha_desasignacion: string = "";

  get nombreCompleto(): string {
    return this.trab_nombre + " " + this.trab_apellidos;
  }

  get nombreCompletoCif(): string {
    let nombre = this.trab_nombre;
    if (this.trab_apellidos) {
      nombre += " " + this.trab_apellidos;
    }
    if (this.trab_nif) {
      nombre += " (" + this.trab_nif + ")";
    }
    return nombre;
  }
}


export function getTiposAcc(): any[] {

  let api = GetAPI();
  let list = [];
  list.push({ "id": "0", "name": api.tr("accidente_recaida"), "desc": api.tr("accidente_recaida_desc") });
  list.push({ "id": "1", "name": api.tr("incidente"), "desc": api.tr("incidente_desc") });
  // list.push({ "id": "2", "name": api.tr("patologia"), "desc": api.tr("patologia_desc") });
  list.push({ "id": "3", "name": api.tr("enfermedad_profesional"), "desc": api.tr("enfermedad_profesional_desc") });
  // list.push({ "id": "4", "name": api.tr("enfermedad_comun"), "desc": api.tr("enfermedad_comun_desc") });
  return list;
}

export class Accidente extends BaseStruct {
  nombre: string = "";
  tecnico_id: number = undefined;
  tecnico: string = "";
  cdt_id: number = undefined;
  cdt: string = "";
  fecha: string = "";
  hora: string = "";
  // hora_trabajo: number = undefined;
  tipo: string = "";
  tipo_id: string = "";
  notas: string = "";
  doc_oblig: number = undefined;
  inv_abiertas: number = undefined;
  estado_doc: EstadoDoc = undefined;

  dm: string = "";
  dm_desc: string = "";
  dm_val_inst: number = undefined;
  dm_val_maq: number = undefined;
  dm_val_herr: number = undefined;
  dm_val_prod: number = undefined;
  dm_val_otros: number = undefined;
  dm_prod_mp: number = undefined;
  dm_prod_sa: number = undefined;
  dm_prod_ac: number = undefined;
  dm_prod_otros: number = undefined;
  dm_desc_rep: string = "";
  dm_forma: string = "";
  dm_forma_texto: string = "";
  dm_agente: string = "";
  dm_agente_texto: string = "";
  dm_responsable_id: number = undefined;
  dm_responsable: string = "";
  dm_responsable_texto: string = "";

  @JsonProperty('-')
  trabajadores: TrabajadorAccidente[] = [];

  @JsonProperty('-')
  documentos: Documento[] = [];

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get timestamp(): Date {

    let dateStr = this.fecha;
    if (dateStr != "" && this.hora != "") {
      dateStr = this.fecha + " " + this.hora;
    }

    let date = moment(dateStr, 'DD-MM-YYYY HH:mm:ss').toDate();
    // console.log("timestamp", "STR", dateStr, "DATE", date);
    return date;
  }

  get dmDesc(): string {

    let api = GetAPI();
    if (this.dm == "0") {
      return "No";
    } else if (this.dm == "1") {
      return "Si";
    }
    return api.tr("sin_especificar");
  }

  get dm_val_total(): number {

    let total = 0;
    if (!isNaN(this.dm_val_inst)) {
      total += this.dm_val_inst
    }

    if (!isNaN(this.dm_val_maq)) {
      total += this.dm_val_maq
    }

    if (!isNaN(this.dm_val_herr)) {
      total += this.dm_val_herr
    }

    if (!isNaN(this.dm_val_prod)) {
      total += this.dm_val_prod
    }

    if (!isNaN(this.dm_val_otros)) {
      total += this.dm_val_otros
    }

    return total;
  }


  get tipoNombre(): string {

    let api = GetAPI();

    switch (this.tipo_id) {
      case "0":
        return api.tr("accidente_recaida")
      case "1":
        return api.tr("incidente")
      case "2":
        return api.tr("patologia")
      case "3":
        return api.tr("enfermedad_profesional")
      case "4":
        return api.tr("enfermedad_comun")
    }
  }
}

export class MedidasPreventiva {
  id: number = undefined;
  nombre: string = "";

}

export class FactoresRiesgo {
  id: number = undefined;
  nombre: string = "";
  @JsonProperty({ clazz: MedidasPreventiva })
  medidas_preventivas: MedidasPreventiva[] = undefined;
}

// export class AccionCorrectiva {
//   id: number = undefined;
//   causa: string = "";
//   causa_nombre: string = "";
//   descripcion: string = "";
//   fecha_limite: string = "";
//   fecha_aprobacion: string = "";
//   responsable: number = undefined;
//   responsable_nombre: string = "";
//   descripcion_verificacion: string = "";
// }


export class TrabajadorAccidente extends BaseStruct {
  trab_id: number = undefined;
  trab_nombre: string = "";
  trab_apellidos: string = "";
  trab_empresa: string = "";
  trab_nif: string = "";
  trab_cod_int: string = "";
  trab_cod_unico: string = "";
  trab_contrato: string = "";
  accidente_id: number = undefined;
  acc_recaida_id: number = undefined;
  // acc_recaida: string = "";
  acc_trafico: number = undefined;
  lugar_codigo: number = undefined;
  // lugar: string = undefined;
  baja_id: number = undefined;
  fecha_baja: string = "";
  fecha_alta: string = "";
  forma_id: string = "";
  forma: string = "";
  forma_agente_id: string = "";
  forma_agente: string = "";
  forma_txt: string = "";
  forma_aparato_txt: string = "";
  parte_id: string = "";
  parte: string = "";
  lesion_id: string = "";
  lesion: string = "";
  naturaleza_id: string = "";
  naturaleza: string = "";
  notas: string = "";
  hora_trabajo: string = "";
  hora_trabajo_id: string = "";
  seccion_id: number = undefined;
  seccion: string = "";
  seccion_trab_id: number = undefined;
  seccion_trab: string = "";
  puesto_trab_id: number = undefined;
  puesto_trab: string = "";
  investigacion_cerrada: number = undefined;
  excluir_firma: number = undefined;
  fecha_comunica: string = undefined;
  estado_doc: EstadoDoc = undefined;
  tecnico_inv_id: number = undefined;
  tecnico_inv: string = "";

  // investigacion
  grado: string = "";
  grado_nombre: string = "";
  posibilidad_repeticion: string = "";
  posibilidad_repeticion_nombre: string = "";
  origen: string = "";
  origen_nombre: string = "";
  riesgo: string = "";
  riesgo_nombre: string = "";
  factor_riesgo: string = "";
  factor_riesgo_nombre: string = "";
  @JsonProperty({ clazz: FactoresRiesgo })
  factor_riesgo_medidas: FactoresRiesgo[] = undefined;
  consecuencias: number = undefined;
  consecuencias_nombre: string = "";
  nivel_exposicion: number = undefined;
  nivel_exposicion_nombre: string = "";
  probabilidad: number = undefined;
  probabilidad_nombre: string = "";
  observaciones_investigacion: string = "";

  // Lugar
  lugar_ccc: string = "";
  lugar_naf: string = "";


  @JsonProperty({ clazz: Accidente })
  acc: Accidente = undefined;

  // BORRAR
  // acciones_correctivas: AccionCorrectiva[] = undefined;

  @JsonProperty({ clazz: MedidaCorrectora })
  med_corr: MedidaCorrectora[] = [];

  get nombreCompleto(): string {
    return this.trab_nombre + " " + this.trab_apellidos;
  }

  get nombreCompletoCif(): string {
    return this.trab_nombre + " " + this.trab_apellidos + " (" + this.trab_nif + ")";
  }

  get bajaDesc(): string {
    if (this.baja_id == 0) {
      return "No"
    } else if (this.baja_id == 1) {
      return "Si"
    }
    return "No";
  }

  get acc_recaidaTxt(): string {
    let tipos = getAccTipoSucesos();

    if (!this.acc_recaida_id) {
      this.acc_recaida_id = 0;
    }

    for (let l of tipos) {
      if (l.key == this.acc_recaida_id) {
        return l.description;
      }
    }
    return "";
  }

  get lugarTxt(): string {

    let lugares = getAccLugar();

    if (!this.lugar_codigo) {
      this.lugar_codigo = 0;
    }

    for (let l of lugares) {
      if (l.key == this.lugar_codigo) {
        return l.description;
      }
    }
    return "";
  }


  get accTraficoBool(): boolean {
    return this.acc_trafico == 1;
  }

  set accTraficoBool(v: boolean) {
    this.acc_trafico = v ? 1 : 0;
  }

  get tecnicoInvNombre(): string {
    if (!this.tecnico_inv || this.tecnico_inv == "") {
      return "";
    }

    return this.tecnico_inv.split(" - ")[0];
  }

  get tecnicoInvEmpresa(): string {
    if (!this.tecnico_inv || this.tecnico_inv == "") {
      return "";
    }

    return this.tecnico_inv.split(" - ")[1];
  }

  get grado_nombre_tr(): string {
    let api = GetAPI();

    return api.tr(this.grado_nombre);
  }

  get timestamp(): Date {

    if (this.acc) {
      return this.acc.timestamp;
    }
    return new Date();
  }

}

export function getAccTipoSucesos(): any[] {

  let api = GetAPI();

  let list = [];
  list.push({ "key": 0, "description": api.tr("accidente") });
  list.push({ "key": 1, "description": api.tr("recaida") });
  // list.push({ "key": 2, "description": api.tr("implicado") });
  return list;
}

export function getAccConSinBaja(): any[] {

  let api = GetAPI();

  let list = [];
  list.push({ "key": 0, "description": api.tr("sin_baja") });
  list.push({ "key": 1, "description": api.tr("con_baja") });
  return list;
}

export function getAccLugar(): any[] {

  let api = GetAPI();

  let list = [];
  list.push({ "key": 0, "description": api.tr("en_centro_habitual") });
  list.push({ "key": 1, "description": api.tr("en_desplazamiento") });
  list.push({ "key": 2, "description": api.tr("en_itinere") });
  list.push({ "key": 3, "description": api.tr("en_otro_centro") });
  return list;
}

export class FormaDeContacto {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class AgenteMaterial {
  id: string = "";
  nombre: string = "";
  pal_clave: string = "";
  nombre_conta: string = "";
  pal_clave_conta: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }

  get palabrasConID(): string {

    let locale = GetAPI().getLocale();

    let desc = this.pal_clave;
    if (locale == "cat" && this.pal_clave_conta != "") {
      desc = this.pal_clave_conta;
    }

    if (desc == "") {
      desc = this.nombre;
      if (locale == "cat" && this.nombre_conta != "") {
        desc = this.nombre_conta;
      }
    }

    if (this.id == "") {
      return desc;
    }
    return this.id + " - " + desc;
  }
}

export class ParteDelCuerpo {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class Lesion {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class NaturalezaDeLaLesion {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class HoraDelTrabajo {
  id: string = "";
  nombre: string = "";
  itinere: number = undefined;

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class ActividadCt {
  id: number = undefined;
  nombre: string = "";
}

export class LugarCt {
  id: number = undefined;
  nombre: string = "";
}

export class MensajeCt {
  id: number = undefined;
  nombre: string = "";
}

export class TipoComision {
  id: number = undefined;
  nombre: string = "";
}

export class TipoInforme {
  id: number = undefined;
  nombre: string = "";
}

export class TipoLibroAct {
  id: number = undefined;
  nombre: string = "";
}

export class TipoRegistroLibro {
  id: number = undefined;
  nombre: string = "";
}

export class TipoEstadoInspeccion {
  id: number = undefined;
  nombre: string = "";
}

export function getGradoLesion(): any[] {
  let api = GetAPI();

  let list = [];
  list.push({ "id": "1", "nombre": api.tr("leve") });
  list.push({ "id": "2", "nombre": api.tr("grave") });
  list.push({ "id": "3", "nombre": api.tr("muy_grave") });
  list.push({ "id": "4", "nombre": api.tr("mortal") });
  return list;
}

export function getPosibilidadRepeticion(): any[] {
  let api = GetAPI();

  let list = [];
  list.push({ "id": "1", "nombre": api.tr("nula") });
  list.push({ "id": "2", "nombre": api.tr("pequeña") });
  list.push({ "id": "3", "nombre": api.tr("grande") });
  return list;
}

export class Riesgo {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class FactorRiesgo {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class Consecuencia {
  id: number = undefined;
  nombre: string = "";
}

export class NivelExposicion {
  id: number = undefined;
  nombre: string = "";
}

export class ProbabilidadSuceso {
  id: number = undefined;
  nombre: string = "";
}

export class CategoriaPEE {
  id: number = undefined;
  nombre: string = "";
}

export class CargoPEE {
  id: number = undefined;
  nombre: string = "";
}

export class TurnoPEE {
  id: number = undefined;
  nombre: string = "";
}


