import { JsonProperty } from 'utils/maputils'
import { BaseStruct } from 'entities/baseStruct'
import { Empresa } from './empresa';
import { EstadoDoc } from './documento';


export class Maquina extends BaseStruct {
  codigo_interno: string = "";
  referencia: string = "";
  nombre: string = "";
  clase: string = "";
  es_componente: number = 0;
  tipo: string = "";
  tipo_maquina: string = "";
  marca: string = "";
  modelo: string = "";
  fabricante: string = "";
  empresa: number = 0;
  tipo_adquisicion: string = "";
  activa: number = 0;
  fecha_adquisicion: string = "";
  fecha_garantia: string = "";
  fecha_fin_uso: string = "";
  matricula: string = "";
  fecha_fabricacion: string = "";
  bastidor_serie: string = "";
  estado: string = "";
  departamento: string = "";
  web: string = "";
  estado_doc: EstadoDoc = undefined;

  @JsonProperty('-')
  empresaCached: Empresa = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

}
