import { GetAPI } from 'utils/api';
import { JsonProperty } from 'utils/maputils'
import { BaseStruct } from 'entities/baseStruct'
import { Direccion } from 'entities/direccion'
import { EstadoDoc } from './documento';
import { Trabajador } from './trabajador';
import { Maquina } from './maquinas';


export class BasicData {
  app_name: string = "";
  app_sub_name: string = "";
  app_web: string = "";
  build_date: string = "";
  use_openid: boolean = false;
}

export class Empresa extends BaseStruct {
  nombre: string = "";
  nombre_fiscal: string = "";
  cif: string = "";
  tipo: string = "";
  direccion: Direccion = undefined;
  estado_doc: EstadoDoc = undefined;
  fecha_constitucion: string = "";
  fecha_inicio: string = "";
  fecha_fin: string = "";
  bloqueada: number = undefined;


  @JsonProperty('-')
  trabajadores: Trabajador[] = [];

  @JsonProperty('-')
  maquinas: Maquina[] = [];

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get nombreCompletoCif(): string {
    return this.nombre + "(" + this.cif + ")";
  }

}

export class EmpresaCCC extends BaseStruct {
  empresa: string = "";
  ccc: string = "";
  provincia_id: string = undefined;
  provincia: string = "";
  provincia_cod: string = "";
  plantilla: number = undefined;
  convenio_id: number = undefined;
  convenio: string = "";

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get nombreCompleto(): string {

    if (this.ccc == "") {
      return GetAPI().tr("todos");
    }
    return this.ccc + " - " + this.provincia_cod + " - " + this.provincia;
  }

}