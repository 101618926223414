import { BaseStruct } from "./baseStruct";

export class Email extends BaseStruct {
    nombre: string = "";
    asunto: string = "";
    de: string = "";
    para: string = "";
    cc: string = "";
    fecha: string = "";
    hora: string = "";
    cuerpo: string = "";
    enviado: number = undefined;

    ////////////////////////////////////////////////////////////////////////////

    constructor() {
        super()
    }

    get cuerpoShort(): string {
        let length = 1000;
        if (this.cuerpo.length > length) {
            return this.cuerpo.substring(0, length);
        }

        return this.cuerpo;
    }
}
