import { JsonProperty } from 'utils/maputils'
import { BaseStruct } from 'entities/baseStruct'
import { computedFrom } from 'aurelia-framework';


export class EstadoDoc extends BaseStruct {
  obligatorio: number = undefined;
  opcional: number = undefined;
  pendiente: number = undefined;
  ok: number = undefined;

  @computedFrom("obligatorio", "ok", "pendiente")
  get validado(): boolean {
    return this.obligatorio == 0 && this.ok > 0 && this.pendiente == 0;
  }

  @computedFrom("obligatorio", "ok", "pendiente")
  get noValidado(): boolean {
    return this.obligatorio == 0 && this.ok > 0 && this.pendiente > 0
  }
}

export class PlantillaTpDoc extends BaseStruct {
  nombre: string = undefined;
  plantilla_id: number = undefined;
}

export class TipoDocumento extends BaseStruct {
  nombre: string = undefined;
  texto: string = undefined;
  max_mb: number = undefined;
  caducidad: number = undefined;
  prohibido_subir: number = undefined;
  no_regenera: number = undefined;
  extensiones: string = undefined;

  atributos: number = undefined;
  atributo_apto_med: number = undefined;
  atributo_apto_med_oblig: number = undefined;
  atributo_calidad: number = undefined;
  atributo_calidad_oblig: number = undefined;

  @JsonProperty({ name: 'plantillas', clazz: PlantillaTpDoc })
  plantillas: PlantillaTpDoc[] = undefined;
}

export enum SeleccionOrigenDocumento {
  TODOS = 0,
  EMPRESA = 1,
  TRABAJADOR = 2,
  MAQUINA = 3,
  CDT = 4
}

export enum Obligatoriedad {
  TODOS = 0,
  OBLIGATORIO = 1,
  NO_OBLIGATORIO = 2
}

export enum OrigenDoc {
  EMPRESA = 1,
  PERSONA = 2,
  TRABAJADOR = 3,
  MAQUINA = 4,
  MAQUINA_EMPRESA = 5,
  CDT = 6,
  PRL = 7,
  ACC = 8,
  EMP_CDT = 9,
  TRAB_CDT = 10,
  HABILITACION = 11,
  TRAB_ACC = 12,
  CURSO = 13,
  MAQ_CDT = 14
}

export class Documento extends BaseStruct {
  codigo: string = undefined;
  nombre: string = undefined;
  pertenece: string = undefined;
  @JsonProperty('pertenece_origen')
  perteneceOrigen: number = undefined;
  tipo: number = undefined;

  @JsonProperty('tipo_nombre')
  tipoNombre: string = undefined;

  tipo_tiene_info: number = undefined;

  @JsonProperty('fecha_firma')
  fechaFirma: string = undefined;

  @JsonProperty('fecha_cad')
  fechaCaducidad: string = undefined;

  @JsonProperty('fecha_sub')
  fechaSubido: string = undefined;

  @JsonProperty('mb')
  tamañoMB: number = undefined;

  subido: number = undefined;
  vigente: number = undefined;
  valido: number = undefined;
  dias: number = undefined;
  estado: string = undefined;
  notas: string = undefined;
  adjunta_com: number = undefined;
  obligatorio: number = undefined;
  obligatorio_indultado: number = undefined;
  disponible: number = undefined;
  prohibido_subir: number = undefined;
  no_regenera: number = undefined;
  extensiones: string = undefined;

  apto_medico_id: string = undefined;
  apto_medico: string = undefined;
  apto_medico_atr: number = undefined;

  calidad_doc_id: string = undefined;
  calidad_doc: string = undefined;
  calidad_doc_atr: number = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }


  get ext(): string {
    if (this.nombre) {
      return this.nombre.split('.').pop();
    }
    return "";
  }


  get isPDF(): boolean {
    let ext = this.ext;
    return ext && ext.toUpperCase() == "PDF";
  }


  get adjuntaCom(): boolean  {
    return this.adjunta_com == 1;
  }

  set adjuntaCom(adj: boolean)  {
    if (adj) {
      this.adjunta_com = 1;
    } else {
      this.adjunta_com = 0;
    }
  }

  get aptoMedico(): boolean  {
    return this.apto_medico_atr == 1;
  }

  get calidadDocumental(): boolean  {
    return this.calidad_doc_atr == 1;
  }
}

export class AptoMedicoLaboral {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}


export class AtributoFormacion {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}

export class AtributoCalidad {
  id: string = "";
  nombre: string = "";

  get nombreConID(): string {
    if (this.id == "") {
      return this.nombre;
    }
    return this.id + " - " + this.nombre;
  }
}
