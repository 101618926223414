import { TrabajadorCdt } from './trabajadorCdt';
import { BaseStruct } from 'entities/baseStruct'
import { Direccion } from 'entities/direccion'
import { EstadoDoc } from './documento';
import { JsonProperty } from 'utils/maputils';
import { Empresa } from './empresa';


export class Centro extends BaseStruct {
  jerarquia: string = "";
  codigo_unico: string = "";
  nombre: string = "";
  tipo: string = "";
  tipo_siglas: string = "";
  fecha_inicio: string = "";
  fecha_final: string = "";
  fecha_final_prevista: string = "";
  direccion: Direccion = undefined;
  estado_doc: EstadoDoc = undefined;
  vis_doc: number = undefined;
  vis_prl: number = undefined;
  vis_acc: number = undefined;
}

export class EmpresaCdT extends BaseStruct {
  empresa_id: number = undefined;
  // centro_id: number = undefined;
  // codigo_unico
  nombre: string = "";
  tipo: string = "";
  agente: string = "";
  contratado_por: string = "";
  activa: number = undefined;
  // direccion: Direccion = undefined;
  fecha_entrada: string = "";
  fecha_salida: string = "";
  estado_doc: EstadoDoc = undefined;
  // estado_doc_ct: EstadoDoc = undefined;
  ct: Centro = undefined;


  @JsonProperty('-')
  empresaCached: Empresa = undefined;

  @JsonProperty('-')
  trabajadoresCdt: TrabajadorCdt[] = [];

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }

  get nombreCodigo() {
    let ret = this.nombre;
    if (this.ct.codigo_unico) {
      ret = this.ct.codigo_unico + " | " + ret;
    }
    return ret;
  }
}


export class AsignacionCdt extends BaseStruct {
  trabajadores: number[] = undefined;
  fecha_entrada: string = undefined;
  hora_entrada: string = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }
}

export class DesasignacionCdt extends BaseStruct {
  trabajadores: number[] = undefined;
  fecha_salida: string = undefined;
  hora_salida: string = undefined;

  ////////////////////////////////////////////////////////////////////////////

  constructor() {
    super()
  }
}
